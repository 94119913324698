'use client'
import Image from 'next/image'
import { useSession } from 'next-auth/react'
import { useEffect, useRef } from 'react'
import AppMenu from './Menu'
import AuthSession from './header/AuthSession'
import LocaleDropdown from './header/LocaleDropdown'
import NotificationDropdown from './header/NotificationDropdown'
import ProfileDropdown from './header/ProfileDropdown'
import logoLight from '@/assets/images/logo-light.png'
import { getHorizontalMenuItems } from '@/helpers/menu'
import { Link, usePathname } from '@/navigation'
import ButtonMobileMenu from '@/components/layouts/header/ButtonMobieMenu'

function Header() {
  const pathname = usePathname()
  const { status, data } = useSession()

  const navbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (pathname === '/') {
      if (navbarRef.current) {
        navbarRef.current.classList.remove('bg-white', 'shadow', 'lg:bg-white')
      }

      document.addEventListener('scroll', (e) => {
        e.preventDefault()
        if (navbarRef.current) {
          if (window.scrollY >= 80) {
            navbarRef.current.classList.add('bg-white', 'shadow', 'lg:bg-white')
          } else {
            navbarRef.current.classList.remove(
              'bg-white',
              'shadow',
              'lg:bg-white'
            )
          }
        }
      })
    } else {
      document.addEventListener('scroll', (e) => {
        if (navbarRef.current) {
          navbarRef.current.classList.add('bg-white', 'shadow', 'lg:bg-white')
        }
      })
      navbarRef.current?.classList.add('bg-white', 'shadow', 'lg:bg-white')
    }
  }, [pathname])

  return (
    <>
      <header
        ref={navbarRef}
        className="fixed top-0 inset-x-0 flex items-center justify-center z-40 w-full lg:bg-transparent bg-white transition-all py-5 px-4 h-[80px]"
        id="navbar"
      >
        <div className="container px-0">
          <nav className="grid grid-cols-2 gap-4">
            <div className="flex items-center justify-start">
              <Link href="/">
                <Image alt="Logo" src={logoLight} width={126} />
              </Link>
              <div className="lg:block hidden ml-10 pl-8 border-l border-[rgba(0, 0, 0, 0.12)]">
                <AppMenu menuItems={getHorizontalMenuItems()} />
              </div>
            </div>
            {status === 'authenticated' ? (
              <div className="lg:flex lg:justify-end lg:items-center h-full hidden">
                <LocaleDropdown />
                <NotificationDropdown />
                <ProfileDropdown />
              </div>
            ) : (
              <div className="lg:flex lg:justify-end lg:items-center align-middle hidden">
                <AuthSession />
              </div>
            )}
            <div className="lg:hidden flex items-center ms-auto">
              <ButtonMobileMenu></ButtonMobileMenu>
            </div>
          </nav>
        </div>
      </header>
    </>
  )
}

export default Header

"use client";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react'
import { toast } from 'sonner'

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const {data: session} = useSession()
  const [message, setMessage] = useState(null);
  const SOCKET_SERVER_URL = `${process.env.NEXT_PUBLIC_SOCKET_SERVER_URL}/ws/booking_car`;

  useEffect(() => {
    if (session?.user?.id) {
      const ws = new WebSocket(SOCKET_SERVER_URL + "-" + session?.user?.id);

      ws.onopen = () => {
        ws.send(JSON.stringify({action: 'connect', room: "booking_car"}));
        console.log('Connected to socket server');
      };

      ws.onmessage = (message) => {
        const dataMes = JSON.parse(message?.data)

        if (dataMes?.action === "connect") {
          return
        }

        setMessage(dataMes);
        toast.success("Có thông báo mới")
      };

      return () => {
        ws.close();
      }
    }
  }, [session?.user?.id]);

  return (
    <WebSocketContext.Provider value={message}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
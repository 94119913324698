"use client";
import Image from 'next/image'
import bell from '@/assets/images/navbar/bell-01.png'
import {Popover, PopoverContent, PopoverTrigger} from '@/components/ui/popover';
import {Button} from "@/components/ui/button";
import NotificationDetail from "@/components/layouts/header/NotificationDetail";
import {useEffect, useState} from "react";
import {useSession} from "next-auth/react";
import notificationApiRequest from "@/api-data/notification";
import {toast} from "sonner";
import { useWebSocket } from '@/app/WebSocketContext'

const SOCKET_SERVER_URL = `${process.env.NEXT_PUBLIC_SOCKET_SERVER_URL}/ws/booking_car`;

function NotificationDropdown() {
  const {data: session} = useSession()
  const [notifications, setNotifications] = useState([])
  const message = useWebSocket();

  const getListNotification = async () => {
    const queryString = new URLSearchParams({
      page: "1",
      limit: "10",
      sort_by: "created_at",
      sort_dir: "desc"
    });

    const {payload} = await notificationApiRequest.getNotifications(queryString.toString(), session?.accessToken || "")

    if (payload.code === 200) {
      setNotifications(payload?.data?.list)
    }
  }

  useEffect(() => {
    getListNotification()
  }, [session?.user?.id, message]);

  return (
    <div className="nav-item">
      <Popover>
        <PopoverTrigger>
          <PopoverToggle2/>
        </PopoverTrigger>
        <PopoverContent>
          <div className="transition-all translate-y-3 bg-white rounded-2.5xl shadow-lg p-5 w-[420px]">
            <h3 className="font-semibold text-gray-900 text-2xl">Thông báo</h3>
            <div className="mt-4">
              <ul className="h-[600px] overflow-y-auto pr-4">
                {notifications.map((item, index) => (
                  <NotificationDetail key={index} index={index} notification={item}/>
                ))}
              </ul>
              <Button
                variant="outline"
                className="w-full border border-base-gray-300 py-3 px-5 rounded-lg mt-4 shadow-btn-1 font-semibold text-base"
                type="button"
              >
                Tất cả thông báo
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

function PopoverToggle2() {
  return (
    <div className="ml-8 cursor-pointer relative">
      <div className="after:content-noti-dot after:absolute after:top-[-15px] after:right-[-6px]">
        <Image alt="notification bell icon" height={24} src={bell} width={24}/>
      </div>
    </div>
  )
}

export default NotificationDropdown